import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

const LearnMorePage = () => {
  return (
    <>

    <Navbar />
    
    <div className="LearnMorePage-container">
      <h1 className="LearnMorePage-title">Tech Generation Summer Camp 2025</h1>
      <p className="LearnMorePage-subtitle">
        Explore the future of technology, creativity, and life skills at <strong>Nihoc International School</strong>, <strong>GD Goenka Hyderabad</strong>, and <strong>Suprabhat Model High School.</strong>
      </p>

      <div className="LearnMorePage-section">
        <h2 className="LearnMorePage-section-title">Camp Overview</h2>
        <p className="LearnMorePage-section-content">
          The Tech Generation Summer Camp is designed to provide both high school (ages 10 to 15) and primary school students (ages 4 to 9) with enriching activities that balance technological education, creative arts, physical activities, and life skills. Highlights include robotics, AI, coding, horse riding, swimming, music, and more!
        </p>
      </div>

      <div className="LearnMorePage-section">
        <h2 className="LearnMorePage-section-title">Key Activities</h2>
        <div className="LearnMorePage-activities-grid">
          <div className="LearnMorePage-activity-card">
            <h2>Primary School (Ages 4 to 9)</h2>
            <ul>
              <li>Photography</li>
              <li>Music (Drums, Guitar, Keyboard)</li>
              <li>Dance (Folk, Salsa, Western)</li>
              <li>Drawing and Painting</li>
              <li>Art and Craft</li>
              <li>Public Speaking</li>
              <li>Chess</li>
              <li>Kids Karting</li>
              <li>Yoga and Calisthenics</li>
            </ul>
          </div>
          <div className="LearnMorePage-activity-card">
            <h2>High School (Ages 10 to 15)</h2>
            <ul>
              <li>Horse Riding</li>
              <li>Photography</li>
              <li>Dance (Salsa, Ballet, Contemporary)</li>
              <li>Coding (React JS Front-end Training)</li>
              <li>Artificial Intelligence (Chatbot Integration & Generative AI)</li>
              <li>Culinary Arts</li>
              <li>Chess</li>
              <li>DJing (Bollywood & Remixing)</li>
              <li>Yoga and Calisthenics</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="LearnMorePage-section">
        <h2 className="LearnMorePage-section-title">Special Events</h2>
        <ul className="LearnMorePage-special-events">
          <li>
            <strong>Musical Night Camping (May 16th):</strong> Campfire, live music, and a special dinner under the stars.
          </li>
          <li>
            <strong>Exhibition (May 22nd):</strong> Showcase of creative and technological projects by students.
          </li>
          <li>
            <strong>Closing Ceremony (May 24th):</strong> Awards and performances to celebrate the camp's success.
          </li>
        </ul>
      </div>

      <div className="LearnMorePage-section">
        <h2 className="LearnMorePage-section-title">Logistics and Fees</h2>
        <p className="LearnMorePage-section-content">
          <strong>Fees:</strong> Early Bird (Before Dec 31, 2024): ₹3499 | Regular (From Jan 1, 2025): ₹3999<br />
          <strong>Transportation:</strong> Available within 20 km radius (₹2000 - ₹5000).
        </p>
      </div>

      <Link to="/enrollment" className="styled-link">
            <button className="button-2">Enroll Today</button>
          </Link>
    </div>
    
    <Footer />
    
    </>
  );
};

export default LearnMorePage;
