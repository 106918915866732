import { Grid, Typography } from "@mui/material";
import React from "react";
import ScrollAdd from "../components/ScrollAdd";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const About = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />

      <div className="about-container">
        <h1 className="heading1">About Us</h1>

        <Typography className="announcement-container">
          <h1 style={{ fontFamily: 'Samarkan, sans-serif' }}>
            Welcome to Learnorama Technologies Pvt Ltd!
          </h1>
          <p>
            We are a pioneering educational platform dedicated to nurturing the potential of young minds through <strong>innovative, experiential learning</strong>. Our mission is to empower the next generation of leaders, thinkers, and creators by providing them with the tools and inspiration to excel in an ever-evolving world.
          </p>
          <p>
            Our recent summer camp was a tremendous success, with over <strong>300 high school students</strong> mastering <strong>Artificial Intelligence</strong> to create their own business websites—a feat that has garnered widespread recognition across numerous media outlets.
          </p>
          <h3><strong>Unveiling Our New Offerings</strong></h3>
          <p>
            Building on this success, we are thrilled to announce the launch of our new <strong>online AI courses</strong> for children aged 9 to 17. These courses are meticulously designed to instill foundational skills in AI, nurturing creativity, problem-solving, and technical acumen in the next wave of innovators.
          </p>
          <p>
            Join us in transforming learning into an exciting, impactful journey for young learners across the globe!
          </p>
          <p>
            For more information, visit our website: <a href="https://learnorama.in" target="_blank" rel="noopener noreferrer">learnorama.in</a>.
          </p>
        </Typography>

        

<div className="ab-mainbox">
      <div className="ab-box1">
        <img src="./images/about-vision-rama.jpeg" alt="About" />
      </div>
      <div className="ab-box2">
        <Typography style={{ textAlign: 'justify' }}>
          <h2 className="para1"><strong>Our Vision</strong></h2>
          <p>
            At <strong>Learnorama</strong>, we believe in the transformative power of education that extends beyond conventional boundaries. Our vision is to craft an innovative platform that provides children with unforgettable experiences, empowering them with skills that shape their futures in meaningful ways.
          </p>
        </Typography>
      </div>
    </div>

        <h2 className="para1"><strong>Who We Are</strong></h2>
        <p className="text">
          Learnorama is the brainchild of <strong className="highlit-points">Dr. Padala Karthik Reddy</strong>, a visionary educator with over 15 years of experience in fostering holistic student development. Dr. Karthik's expertise in organizing dynamic summer camps and his passion for combining education with creativity and enjoyment have created a distinctive learning environment that stands apart.
        </p>

        <h2 className="para1"><strong>Our Journey</strong></h2>
        <p className="text">
          Learnorama has evolved from a humble initiative to a <strong className="highlit-points">dynamic hub of educational adventures</strong>. Dr. Padala Karthik Reddy's extensive experience in launching various children's events and winning numerous awards over the years laid a strong foundation for innovation and creativity. A significant turning point came when Karthik's path crossed with <strong className="highlit-points">Mr. Harish </strong> in 2024. This meeting of minds, where experience met technology, led to the founding of <strong className="highlit-points">Learnorama Technologies Pvt Ltd</strong>.
        </p>
        <p className="text">
          What began as <strong className="highlit-points">Creative Kids and Unique Creations</strong>—a company centered on summer camps and children's events—transformed into Learnorama, now a leader in <strong className="highlit-points">Artificial Intelligence education for kids</strong> and <strong className="highlit-points">AI-driven business strategies</strong> for organizations. Together, we are breaking new ground in experiential learning and innovative educational approaches. By combining our rich experience and cutting-edge technology, we are committed to making learning a journey of discovery and growth for every child.
        </p>

        <h2 className="para1"><strong>What Distinguishes Us</strong></h2>
        <ul className="list">
          <li className="list-item">
            <strong>Experienced Leadership:</strong> Dr. Karthik’s profound expertise and dedication to the holistic development of children set the foundation for Learnorama’s unique approach to learning.
          </li>
          <li className="list-item">
            <strong>Innovative Programs:</strong> We continuously strive to introduce groundbreaking programs that stimulate curiosity, innovation, and critical thinking in every child.
          </li>
          <li className="list-item">
            <strong>Experiential Learning:</strong> Our hands-on approach ensures that children not only learn but also experience concepts in memorable, enjoyable ways.
          </li>
          <li className="list-item">
            <strong>AI-Driven Business Solutions:</strong> Beyond education, we offer comprehensive <strong className="highlit-points">AI solutions for businesses</strong>, including <strong className="highlit-points">AI marketing, SEO, web design, content creation, strategic marketing, and product management</strong>. Our services are designed to enhance your digital presence, drive engagement, and support your growth in the digital era.
          </li>
        </ul>

        <h2 className="para1"><strong>Our Commitment</strong></h2>
        <ul className="list">
          <li className="list-item">
            <strong>Excellence:</strong> We are devoted to delivering excellence in every facet of our programs, from content to experience.
          </li>
          <li className="list-item">
            <strong>Inclusivity:</strong> Learnorama welcomes children from all backgrounds, ensuring every child has the opportunity to learn, grow, and succeed.
          </li>
          <li className="list-item">
            <strong>Safety:</strong> The safety and well-being of our participants are paramount. We adhere to stringent safety protocols to provide a secure learning environment.
          </li>
        </ul>

        <h2 className="para1"><strong>Our Achievements</strong></h2>
        <p className="text">
          Under the mentorship of Dr. Karthik, many students have risen to national and international acclaim. His son, <strong className="highlit-points">Padala Adharva Reddy</strong>, and nephew <strong className="highlit-points">G. Annika Reddy</strong> have become child prodigies, inspiring the inception of <strong className="highlit-points">Life Histo</strong> and <strong className="highlit-points">Learnorama</strong>—further enhancing the educational journey for children.
        </p>

        <h2 className="para1"><strong>Join Us on This Learning Adventure</strong></h2>
        <p className="text">
          Whether you are a parent seeking a vibrant, engaging summer camp or an educator passionate about innovative programs, we invite you to join us on this thrilling educational adventure. At <strong className="highlit-points">Learnorama</strong>, where education meets exploration, every child is empowered to discover, learn, and create.
        </p>

        <p>
          For more information, please contact us at <a href="mailto:info@learnorama.in" className="highlit-points">info@learnorama.in</a>.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default About;
