import React, { useState } from 'react';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import EventIcon from '@mui/icons-material/Event';
import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from 'react-router-dom';
import Chatbot from './Chatbot'; // Import Chatbot component

const actions = [
  { icon: <ChatIcon />, name: 'ChatBot', path: 'chat' },
  { icon: <CurrencyRupeeIcon />, name: '₹1', path: '/registration' },
  { icon: <EventIcon />, name: 'Upcoming Event TECH GENERATION SUMMER CAMP 2025', path: '/learnmorepage' },
  { icon: <WhatsAppIcon />, name: 'WhatsApp', path: 'https://api.whatsapp.com/send?phone=9849856143' },
  { icon: <FacebookIcon />, name: 'Facebook', path: 'https://www.facebook.com/profile.php?id=61556653161005' },
  { icon: <YouTubeIcon />, name: 'YouTube', path: 'https://www.youtube.com/@Learnorama3' },
  
];

const SpeedDialComponent = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (path) => {
    if (path === 'chat') {
      setIsChatOpen(!isChatOpen);
    } else if (path.startsWith('http')) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          '& .MuiSpeedDial-fab': {
            backgroundColor: 'rgb(255, 81, 0);',
            '&:hover': {
              backgroundColor: 'rgb(255, 81, 0);',
            },
          },
        }}
        icon={<SpeedDialIcon sx={{ color: '#fff', background: 'rgb(255, 81, 0);' }} />}
        direction="up"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.path)}
          />
        ))}
      </SpeedDial>
      <Chatbot isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </>
  );
};

export default SpeedDialComponent;
