import React from 'react';
import { Link } from 'react-router-dom';

const UpcomingEvent = () => {
  return (
    <div className="upcoming-event">
      <h1 className="event-title animatable bounceInRight">UPCOMING EVENT</h1>

      <div className="event-grid animatable bounceInLeft">
        <div className="event-card">
          <img
            src="./images/nihoc.png"
            alt="Tech Camp"
            className="event-image"
          />
          <h2 className="event-heading">TECH GENERATION SUMMER CAMP 2025</h2>
          <p className="event-organizer">Organized by Learnorama.com Pvt Ltd</p>
          <p className="event-venue">Venue: Nihoc International School</p>
          <Link to="/learnmorepage" className="styled-link">
            <button className="button-2">Learn More</button>
          </Link>
        </div>

        <div className="event-card">
          <img
            src="./images/GDLOGO.png"
            alt="Tech Camp"
            className="event-image"
          />
          <h2 className="event-heading">TECH GENERATION SUMMER CAMP 2025</h2>
          <p className="event-organizer">Organized by Learnorama.com Pvt Ltd</p>
          <p className="event-venue">Venue: GD Goenka Hyderabad</p>
          <Link to="/learnmorepage" className="styled-link">
            <button className="button-2">Learn More</button>
          </Link>
        </div>

        <div className="event-card">
          <img
            src="./images/Suprabhat-Model-High-School.png"
            alt="Tech Camp"
            className="event-image"
          />
          <h2 className="event-heading">TECH GENERATION SUMMER CAMP 2025</h2>
          <p className="event-organizer">Organized by Learnorama.com Pvt Ltd</p>
          <p className="event-venue">Venue: Suprabhat Model High School</p>
          <Link to="/learnmorepage" className="styled-link">
            <button className="button-2">Learn More</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEvent;
